<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab>
                {{ $t("Карточка") }}
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item>
                <v-form class="wrapperForm" ref="form" lazy-validation>
                    <v-row dense>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Сведения о регистрации-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Контрагент")}}</div>

                                    <!--поле Наименование-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Наименование")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Name ? dataSource.Data.Object.Name : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="dataSource.Data.Object.Name"
                                                @input="update({ property: 'Data.Object.UniName.ru-RU', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Краткое наименование-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Краткое_наименование")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.ShortName ? dataSource.Data.Object.ShortName : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="dataSource.Data.Object.ShortName"
                                                @input="update({ property: 'Data.Object.UniShortName.ru-RU', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Резидент РК-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Резидент_рк")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.IsResident ? $t("Да") : $t("Нет") }}
                                            </div>
                                            <v-switch
                                                v-else
                                                :input-value="dataSource.Data.Object.IsResident"
                                                @change="update({ property: 'Data.Object.IsResident', value: $event })"
                                                inset
                                                hide-details
                                                class="cust-switch"
                                            />
                                        </v-col>
                                    </v-row>

                                    <!--поле БИН-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("БИН")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.INN ? dataSource.Data.Object.INN : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="dataSource.Data.Object.INN"
                                                @input="update({ property: 'Data.Object.INN', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Отрасль-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Отрасль")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{
                                                    dataSource.Data.Object.Sector != null && dataSource.Data.Object.Sector !== 0
                                                        ? industries.reduce( function (names, industry) {
                                                                if ( (industry.id & dataSource.Data.Object.Sector) > 0) {
                                                                    names.push(`"${industry.Value}"`);
                                                                }
                                                                return names;
                                                            }, []).join(", ")
                                                        : $t("Не_указано")
                                                }}
                                            </div>
                                            <v-select
                                                v-else
                                                :items="industries"
                                                multiple
                                                item-value="id"
                                                :item-text="item => $refLocale(item, $i18n.locale)"
                                                :value="getSectorValues"
                                                @input="update({ property: 'Data.Object.Sector', value: getSector($event) })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col v-if="isViewMode" cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Сведения о регистрации-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Дополнительные_сведения")}}</div>

                                    <!--поле Министерство-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Министерство")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ dataSource.Data.AdditionalObjects.IsMinisterstvo ? $t("Да") : $t("Нет") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <!--поле Министерство-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Областные_департаменты__управления_и_комитеты")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ dataSource.Data.AdditionalObjects.IsOblOrgan ? $t("Да") : $t("Нет") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <!--поле Министерство-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Акиматы_районов_и_города")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ dataSource.Data.AdditionalObjects.IsRegionAkimat ? $t("Да") : $t("Нет") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <!--поле Министерство-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Областные_тоо__ао__оао")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ dataSource.Data.AdditionalObjects.IsOblBusiness ? $t("Да") : $t("Нет") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <!--поле Министерство-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Идентификатор_в_атласе")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ dataSource.Data.Object.AtlasId ? dataSource.Data.Object.AtlasId : $t("Не_указано") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Контакты") }}</div>
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{ $t("Контакты") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="10">
                                            <div class="onlyReadData more-per-lab-wrap">
                                                <v-contact-item
                                                    v-for="contact in contacts.filter(x => !x.DelRec)"
                                                    :key="contact.id"
                                                    :type="contact.__type === 'ContactPhoneNew:#Avrora.Objects.Common' ? 'phone': 'email'"
                                                    :title="contact.Value"
                                                    :description="contact.Description"
                                                    :hide-load="true"
                                                    :is-read-only="isViewMode"
                                                    @editcontact="onEditContact" 
                                                    @deletecontact="onDeleteContact"
                                                />

                                                <v-chip
                                                    class="person-label immutable-text"
                                                    small
                                                    label
                                                    outlined
                                                    v-if="!isViewMode"
                                                    @click="onAddContact"
                                                >
                                                    <i class="fas fa-plus dark-gray-color"></i>
                                                    <div>{{ $t("Добавить_контакт") }}</div>
                                                </v-chip>

                                                <div 
                                                    v-if="isViewMode && contacts.length == 0"
                                                    class="cfl-placeholder"
                                                >
                                                    {{ $t("Контакты_не_найдены") }}
                                                </div>

                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-form>
            </v-tab-item>

        </v-tabs-items>

        <ContactsDlg ref="ContactsRef"
            @on-save-contact="onSaveContact"
        />

    </div>
</template>

<script>
import i18n from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import _ from 'lodash';
import ContactsDlg from '@/components/dialogs/ContactsDlg';
import sys from '@/services/system';

export default {
    name: "ContractorEnterprise",
    components: {
       Toolbar,
       ContactsDlg
    },
    data: () => ({
        tab: null,
        industries: [
            { id: 0x01, Value: i18n.t("Промышленность") },
            { id: 0x02, Value: i18n.t("Сельское_хозяйство") },
            { id: 0x04, Value: i18n.t("Лесное_хозяйство") },
            { id: 0x08, Value: i18n.t("Транспорт") },
            { id: 0x10, Value: i18n.t("Связь") },
            { id: 0x20, Value: i18n.t("Строительство") },
            { id: 0x40, Value: i18n.t("Торговля_и_питание") },
            { id: 0x80, Value: i18n.t("Материально_техническое_обеспечение_производства__") },
            { id: 0x100, Value: i18n.t("Информационно_вычислительное_обслуживание") },
            { id: 0x200, Value: i18n.t("Общая_коммерческая_деятельность") },
            { id: 0x400, Value: i18n.t("Геология__геодезия") },
            { id: 0x800, Value: i18n.t("Жилищное_хозяйство") },
            { id: 0x1000, Value: i18n.t("Коммунальное_хозяйство") },
            { id: 0x2000, Value: i18n.t("Здравоохранение_и_физическая_культура") },
            { id: 0x4000, Value: i18n.t("Образование") },
            { id: 0x8000, Value: i18n.t("Культура_и_искусство") },
            { id: 0x10000, Value: i18n.t("Наука") },
            { id: 0x20000, Value: i18n.t("Финансирование__кредитование__страхование") },
            { id: 0x40000, Value: i18n.t("Управление") },
            { id: 0x80000, Value: i18n.t("Государственная_собственность") }
        ]
    }),
    computed: {
        ...mapGetters('actionsource', { dataSource: 'getDataSource' }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
        }),
        isViewMode() {
            return this.dataSource?.Data.FormId === "0101021";
        },
        getSectorValues() {
            let sector = this.dataSource.Data.Object.Sector;
            return this.industries.reduce( function (keys, industry) {
                if ( (industry.id & sector) > 0) {
                    keys.push(industry.id);
                }
                return keys;
            }, []);
        },
        contacts() {
            if (this.dataSource?.Data?.Object?.ContactList == null)
                return [];
            
            return this.dataSource.Data.Object.ContactList.data_list;
        }
    },
    methods: {
        ...mapActions('actionsource', { updateSource: 'updateDataSource' }),
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => {return i.split('/')[1]})
            
            if (actions.includes(button.Action)) {
                if(button.Action == 'Save' || button.Action == 'SaveAndClose') {
                    let validate_result = this.$refs.form.validate();

                    if(validate_result)
                        await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
                    else
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                }
                else {
                    await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
                }
            }
            else
                this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
        },
        getSector(valuesArray) {
            return valuesArray.reduce( function (sectorValue, industry) {
                return sectorValue | industry;
            }, 0);
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250),
        async onAddContact() {
            await this.openContactDialog(null);
        },
        async onSaveContact(contact) {
            if (!this.dataSource.Data.Object.ContactList) {
                this.updateSource({ property: `Data.Object.ContactList`, value: { data_list: [] } });
                await this.$nextTick();
            }
                        
            let contacts = JSON.parse(JSON.stringify(this.dataSource.Data.Object.ContactList.data_list));
            
            if (contact.isNew) {
                let newContact =
                {
                    __type: contact.type === 'phone' ? "ContactPhoneNew:#Avrora.Objects.Common" : "ContactEMailNew:#Avrora.Objects.Common",
                    DelRec: false,
                    Type: contact.type === 'phone' ? 2 : 1,
                    Value: contact.value,
                    Description: contact.description,
                    id: sys.generateUUID(),
                    is_new_record: true
                };
                contacts.push(newContact);
            }
            else {
                let existedContact = contacts.find(x => x.id === contact.id);
                
                if (existedContact) {
                    existedContact.__type =  contact.type === 'phone' ? "ContactPhoneNew:#Avrora.Objects.Common" : "ContactEMailNew:#Avrora.Objects.Common",
                    existedContact.Value = contact.value;
                    existedContact.Description = contact.description;
                }
            }

            this.updateSource({ property: `Data.Object.ContactList.data_list`, value: contacts });
        },
        async onEditContact(contactId) {
            let existedContact = this.dataSource.Data.Object.ContactList.data_list.find(x => x.id === contactId);

            if (existedContact)
                await this.openContactDialog({
                    id: existedContact.id,
                    type: existedContact.__type === 'ContactPhoneNew:#Avrora.Objects.Common' ? 'phone': 'email',
                    value: existedContact.Value,
                    description: existedContact.Description
                });
        },
        async onDeleteContact(contactId) {
            let existedContact = this.dataSource.Data.Object.ContactList.data_list.find(x => x.id === contactId);

            if (existedContact)
                this.updateSource({ property: `Data.Object.ContactList.data_list[${this.dataSource.Data.Object.ContactList.data_list.indexOf(existedContact)}].DelRec`, value: true });
        },
        async openContactDialog(contact) {
            try {
                await this.$refs.ContactsRef.open(contact);
            } 
            catch (ex) {
                console.log(ex);
            }
        }
    },
    updated() {
        if(this.dataSource !== null){
            this.$refs?.form?.validate();
        }  
    },
    async created() {        
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs.form.validate();
        })();
    },
}
</script>